<template>
    <section class="adventages">
        <div class="container">
            <h2 class="adventages_title">Главные достижения</h2>
            <p class="adventages_descript">Здесь представлен перечень главных достижений спортивного клуба “Кронверские
                Барсы”. </p>
            <Swiper :slidesPerView="3" :spaceBetween="20" class="adventages_slider">
                <SwiperSlide v-for="award in mainAdv" :key="award.id">
                    <AdvantagesCard :award="award"></AdvantagesCard>
                </SwiperSlide>
            </Swiper>
        </div>
    </section>
    <section class="own">
        <div class="container">
            <div class="own_block">
                <h2 class="own_title">Предложи свое достижение </h2>
                <p class="own_descript">Если у вас есть желание самому добавить личное достижение, то вы можете перейти
                    по ссылке ниже и добавить его самостоятельно. </p>
                <router-link to="/offeraward" class="own_link">Подробнее <img src="@/assets/img/icons/arrow_right.svg" alt=""></router-link>
            </div>
        </div>
    </section>
    <section class="adventages">
        <div class="container">
            <h2 class="adventages_title">Новые достижения</h2>
            <p class="adventages_descript">Здесь представлен перечень главных достижений спортивного клуба “Кронверские
                Барсы”. </p>
            <Swiper :slidesPerView="3" :spaceBetween="20" class="adventages_slider">
                <SwiperSlide v-for="award in newAdv" :key="award.id">
                    <AdvantagesCard :award="award"></AdvantagesCard>
                </SwiperSlide>
            </Swiper>
        </div>
    </section>
</template>
<script setup lang="ts">
const api = 'https://stage-awards.kronbars.ru'

import 'swiper/css';
import 'swiper/css/pagination';

import { Swiper, SwiperSlide } from 'swiper/vue';
import { onMounted, ref } from 'vue'

import AdvantagesCard from '@/components/AdvantagesCard.vue';
import axios from 'axios';

const mainAdv: Record<any, any> = ref([])
const newAdv: Record<any, any> = ref([])

onMounted(() => {
    /**
     * Получение наград для главной страницы
     */
    try {
        axios.get(api + '/api/awards/summaries')
            .then((res) => {
                const result = res.data

                mainAdv.value = result.main_awards
                newAdv.value = result.new_awards
            })
            .catch((err) => {
                console.log('Ошибка при получении данных для главной странице: ' + err);
                return err;
            })
    } catch {
        console.log('Ошибка при получении данных для главной странице');
    }


})
</script>
<style lang="sass" scoped>
.adventages
    &_title
        text-align: center
        font-size: 36px
        font-weight: 300
        color: #0A0A1C
        margin-bottom: 10px
    &_descript
        color: #7F8490
        font-size: 24px
        line-height: 34px
        margin: 0 auto
        max-width: 841px
        width: 100%
        text-align: center
    &_slider
        margin-top: 40px
.own 
    &_block
        background: #0B1428
        max-width: 753px
        margin: 0 auto
        border-radius: 16px
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
        padding: 30px 40px
        position: relative
        z-index: 1
        &::before
            content: ""
            width: 263px
            height: 100%
            position: absolute
            right: 0
            top: 0
            background-image: url('@/assets/img/bars.png')
            background-size: cover
            background-position: center center
            background-repeat: no-repeat

    &_title 
        color: #fff
        font-size: 32px
        letter-spacing: 1%
        font-weight: 600
    &_descript
        max-width: 458px
        font-size: 20px
        font-weight: 100
        line-height: 33px
        letter-spacing: 1%
        color: #fff
        margin-top: 20px
    &_link 
        margin-top: 25px
        padding: 20px 28px
        background: #fff
        color: #1F1F1F
        font-weight: 500
        font-size: 16px
        border-radius: 8px
        width: fit-content
        display: flex
        align-items: center
        gap: 8px
        img
            width: 20px
</style>