import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/icons/sport/soccer.svg'
import _imports_1 from '@/assets/img/icons/calendar.svg'
import _imports_2 from '@/assets/img/icons/check.svg'
import _imports_3 from '@/assets/img/icons/graph.svg'


const _hoisted_1 = { class: "adventages_item" }
const _hoisted_2 = { class: "adventagesItem_img" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "adventagesItem_main" }
const _hoisted_5 = { class: "adventagesItemMain_header" }
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "adventagesItemMain_info" }
const _hoisted_8 = { class: "adventagesItemMainInfo_item" }
const _hoisted_9 = { class: "adventagesItemMainInfoItem_title" }
const _hoisted_10 = { class: "adventagesItemMainInfo_item" }
const _hoisted_11 = { class: "adventagesItemMainInfoItem_title" }
const _hoisted_12 = { class: "adventagesItemMainInfo_item" }
const _hoisted_13 = { class: "adventagesItemMainInfoItem_title" }
const _hoisted_14 = { class: "adventagesItemMainInfo_item" }
const _hoisted_15 = { class: "adventagesItemMainInfoItem_title" }
const _hoisted_16 = { class: "adventagesItemMain_descript" }



/* eslint-disable */
const api = 'https://stage-awards.kronbars.ru'


export default /*@__PURE__*/_defineComponent({
  __name: 'AdvantagesCard',
  props: {
    award: {}
  },
  setup(__props: any) {



const getNormalDate = (isoDate: string) => {

    const date = new Date(isoDate);

    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    const formattedDate = `${day}.${month}.${year}`;

    return formattedDate
}


return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: api + _ctx.award.photos[0].url,
        alt: "advImg"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", {
          class: "adventagesItemMain_title",
          title: _ctx.award.title
        }, _toDisplayString(_ctx.award.title), 9, _hoisted_6),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "adventagesItemMainInfoItem_icon" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "Icon"
              })
            ], -1)),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.award.sportType.name), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "adventagesItemMainInfoItem_icon" }, [
              _createElementVNode("img", {
                src: _imports_1,
                alt: "Icon"
              })
            ], -1)),
            _createElementVNode("p", _hoisted_11, _toDisplayString(getNormalDate(_ctx.award.dateReceive)), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "adventagesItemMainInfoItem_icon" }, [
              _createElementVNode("img", {
                src: _imports_2,
                alt: "Icon"
              })
            ], -1)),
            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.award.competitionLevel.name), 1)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "adventagesItemMainInfoItem_icon" }, [
              _createElementVNode("img", {
                src: _imports_3,
                alt: "Icon"
              })
            ], -1)),
            _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.award.place.name), 1)
          ])
        ]),
        _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.award.description), 1)
      ]),
      _createVNode(_component_RouterLink, {
        to: '/awards/' + _ctx.award.id,
        class: "adventagesItemMain_link"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("Подробнее")
        ])),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}
}

})