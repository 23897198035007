<template>
    <header>
        <div class="header_container">
            <RouterLink to="/" class="header_logo"><img src="/logo.svg" alt="Header.logo"></RouterLink>
            <div class="header_info">
                <RouterLink class="headerInfo_link" to="/">Раритетные кубки</RouterLink>
                <RouterLink class="headerInfo_link" to="/awards">Все награды</RouterLink>
                <form class="headerInfo_form">
                    <input class="headerInfoForm_input" type="text" placeholder="Поиск по названию наград">
                </form>
            </div>
        </div>
    </header>
</template>
<script setup lang="ts">
</script>
<style lang="sass" scoped>
header 
    position: absolute
    top: 0
    left: 0
    width: 100%
    padding: 10px 0px
    z-index: 2
.header
    &_container
        padding: 0px 40px
        display: flex
        align-items: center
        justify-content: space-between
    &_logo
        max-width: 124px
        width: 100%
    &_info
        display: flex
        align-items: center
        gap: 40px
        max-width: 650px
        width: 100%
    &Info
        &_link 
            font-size: 20px
            font-weight: 200
            color: #fff
        &_form 
            max-width: 266px
            width: 100%

        &Form
            &_input 
                background: rgba(217, 217, 217, 0.22)
                border-radius: 7px
                font-size: 16px
                font-weight: 200
                color: #fff
                width: 100%
                padding: 5px 0px
                padding-left: 5px
</style>