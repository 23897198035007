<template>
    <footer>
        <div class="footer_block">
            <div class="footer_logo"><img src="@/assets/img/footer_logo.svg" alt="FooterLogo"></div>
            <a href="mailto: info@terracash.pro" class="footer_link">info@terracash.pro</a>
            <a href="tel: +79112561879" class="footer_link">8 911 256 18 79</a>
        </div>
    </footer>
</template>
<style lang="sass" scoped>
footer
    background: #0B1428
    border-top-left-radius: 30px
    border-top-right-radius: 30px
    display: flex
    justify-content: center
    text-align: center
.footer
    &_block
        padding: 40px 0px
    &_logo
        max-width: 262px
        width: 100%
        margin-bottom: 37px
    &_link
        color: #fff
        font-size: 20px
        font-weight: 200
        margin-bottom: 37px
        &:last-child
            margin-bottom: 0px

</style>