import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent } from "vue"
import _imports_0 from '@/assets/img/icons/arrow_right.svg'


const _hoisted_1 = { class: "adventages" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "own" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "own_block" }
const _hoisted_6 = { class: "adventages" }
const _hoisted_7 = { class: "container" }

import 'swiper/css';
import 'swiper/css/pagination';

import { Swiper, SwiperSlide } from 'swiper/vue';
import { onMounted, ref } from 'vue'

import AdvantagesCard from '@/components/AdvantagesCard.vue';
import axios from 'axios';

const api = 'https://stage-awards.kronbars.ru'


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props) {

const mainAdv: Record<any, any> = ref([])
const newAdv: Record<any, any> = ref([])

onMounted(() => {
    /**
     * Получение наград для главной страницы
     */
    try {
        axios.get(api + '/api/awards/summaries')
            .then((res) => {
                const result = res.data

                mainAdv.value = result.main_awards
                newAdv.value = result.new_awards
            })
            .catch((err) => {
                console.log('Ошибка при получении данных для главной странице: ' + err);
                return err;
            })
    } catch {
        console.log('Ошибка при получении данных для главной странице');
    }


})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "adventages_title" }, "Главные достижения", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "adventages_descript" }, "Здесь представлен перечень главных достижений спортивного клуба “Кронверские Барсы”. ", -1)),
        _createVNode(_unref(Swiper), {
          slidesPerView: 3,
          spaceBetween: 20,
          class: "adventages_slider"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mainAdv.value, (award) => {
              return (_openBlock(), _createBlock(_unref(SwiperSlide), {
                key: award.id
              }, {
                default: _withCtx(() => [
                  _createVNode(AdvantagesCard, { award: award }, null, 8, ["award"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "own_title" }, "Предложи свое достижение ", -1)),
          _cache[4] || (_cache[4] = _createElementVNode("p", { class: "own_descript" }, "Если у вас есть желание самому добавить личное достижение, то вы можете перейти по ссылке ниже и добавить его самостоятельно. ", -1)),
          _createVNode(_component_router_link, {
            to: "/offeraward",
            class: "own_link"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Подробнее "),
              _createElementVNode("img", {
                src: _imports_0,
                alt: ""
              }, null, -1)
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "adventages_title" }, "Новые достижения", -1)),
        _cache[6] || (_cache[6] = _createElementVNode("p", { class: "adventages_descript" }, "Здесь представлен перечень главных достижений спортивного клуба “Кронверские Барсы”. ", -1)),
        _createVNode(_unref(Swiper), {
          slidesPerView: 3,
          spaceBetween: 20,
          class: "adventages_slider"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(newAdv.value, (award) => {
              return (_openBlock(), _createBlock(_unref(SwiperSlide), {
                key: award.id
              }, {
                default: _withCtx(() => [
                  _createVNode(AdvantagesCard, { award: award }, null, 8, ["award"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}
}

})