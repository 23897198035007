import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TheHeader),
    _createElementVNode("main", null, [
      _cache[0] || (_cache[0] = _createElementVNode("section", { class: "main" }, [
        _createElementVNode("div", { class: "container" }, [
          _createElementVNode("div", { class: "main_block" }, [
            _createElementVNode("h1", { class: "main_title" }, [
              _createTextVNode("цифровой музей "),
              _createElementVNode("br"),
              _createElementVNode("span", null, "достижений")
            ])
          ])
        ])
      ], -1)),
      _createVNode(_component_router_view)
    ]),
    _createVNode(TheFooter)
  ], 64))
}
}

})